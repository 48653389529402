
import {ref, getCurrentInstance, reactive, toRefs, onMounted, inject} from 'vue';
export default {
    name: "Welcome",
    setup(){
        const welcomeRef=ref(null);
        const {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        // let mainComp=inject('mainComp') as any;
        const dataObj=reactive({
            hasEditPortal:(JSON.parse(utils.UtilPub.decrypt(sessionStorage.getItem("userInfo"))) as IStoreUserInfo).hasEditPortal, //用户是否有编辑定制portal的权限
        })
        onMounted(()=>{
        })
        return{
            ...toRefs(dataObj),welcomeRef
        }
    },
    components: {}
};